<template>
  <v-navigation-drawer
    v-if="displayDrawer"
    id="core-drawer"
    ref="coreDrawer"
    v-model="drawerRender"
    app
    :dark="barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    width="260"
    v-bind="$attrs"
    color="secondary"
    style="z-index: 12"
  >
    <!-- src="@/assets/MMS-2019-homepage_2500x1500.jpg" -->
    <!-- <template v-slot:img="props">
      <v-img
        gradient="to bottom right, #A25EB5, #A25EB5, #A25EB5"
        v-bind="props"
      />
    </template> -->

    <router-link :to="{ name: 'CampaignDashboard' }">
      <v-img
        v-show="!showDrawerImgIcon"
        src="@/assets/logo_white.png"
        class="ma-2"
      />
      <v-img
        v-show="showDrawerImgIcon"
        src="@/assets/logo_white_icon.png"
        class="mx-2 my-7"
      />
    </router-link>

    <v-divider class="my-2" />

    <v-list
      expand
      nav
    >
      <div />

      <template v-for="(item, i) in computedItems">
        <v-divider
          v-if="item.divider"
          :key="`divider-${i}`"
          class="my-2"
        />
        <base-item-group
          v-else-if="item.children && displayDrawerItem(item) && item.children.some(x => displayDrawerItem(x))"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else-if="!item.children && displayDrawerItem(item)"
          :key="`item-${i}`"
          :item="item"
        />
        <div
          v-else
          :key="`item-${i}`"
          hidden
        />
      </template>

      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState, mapMutations, mapGetters } from 'vuex'
import { NOWWUrl } from '@/shared/axios-config'
import claims from '@/shared/models/auth/claims'

require('@/assets/logo_white.png')
require('@/assets/logo_white_icon.png')

export default {
  name: 'CoreDrawer',

  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    showDrawerImgIcon: false,
    items: [
      {
        icon: 'mdi-monitor-dashboard',
        title: 'CSM Dashboard',
        hasClaim: claims.MMS_TEBT,
        to: '/dashboard/csmDashboard'
      },
      {
        icon: 'mdi-bullseye-arrow',
        title: 'Target Audiences',
        to: '/dashboard/targetAudience'
      },
      {
        icon: 'mdi-email-multiple',
        title: 'Campaigns',
        to: '/dashboard/campaign',
        hasClaim: claims.BS_TB
      },
      {
        icon: 'mdi-chart-box',
        title: 'Reporting',
        to: '/reports/dashboard'
      },
      {
        icon: 'mdi-bullseye',
        title: 'Target Data',
        hasClaim: claims.ITA_TFM,
        // to: {
        //   name: 'NowwRedirect',
        //   params: { nowwRoute: 'meimport_main.aspx' }
        // }
        to: '/TargetData'
      },
      {
        group: '',
        icon: 'mdi-human-capacity-decrease',
        title: 'Suppression',
        children: [
          {
            icon: 'mdi-at',
            title: 'Email',
            to: 'suppression/emailSuppression',
            hasClaim: claims.ITA_ES
          },
          {
            icon: 'mdi-earth',
            title: 'Global',
            to: 'suppression/globalSuppression',
            hasClaim: claims.ITA_GES
          }
        ]
      },
      // {
      //   group: '',
      //   icon: 'mdi-human-capacity-decrease',
      //   title: 'Suppression',
      //   children: [
      //     {
      //       icon: 'mdi-at',
      //       title: 'Email',
      //       to: {
      //         name: 'NowwRedirect',
      //         params: { nowwRoute: 'suppression_main.aspx' }
      //       },
      //       hasClaim: claims.ITA_ES
      //     },
      //     {
      //       icon: 'mdi-earth',
      //       title: 'Global',
      //       to: {
      //         name: 'NowwRedirect',
      //         params: { nowwRoute: 'global_suppression_main.aspx' }
      //       },
      //       hasClaim: claims.ITA_GES
      //     }
      //   ]
      // },
      {
        icon: 'mdi-doctor',
        title: 'Dr. Lookup',
        hasClaim: claims.ITA_PL,
        // to: {
        //   name: 'NowwRedirect',
        //   params: { nowwRoute: 'doctor-lookup.aspx' }
        // }
        to: '/DrLookup'
      },
      {
        icon: 'mdi-account-multiple',
        title: 'Account',
        to: '/account',
        hasClaim: claims.AD_AS
      },
      {
        group: '',
        icon: 'mdi-library',
        title: 'Content',
        hasClaim: claims.CL_CLM,
        children: [
          {
            icon: 'mdi-folder-multiple-image',
            title: 'Images',
            to: 'contentLibrary/images',
            hasClaim: claims.CL_CLM
          },
          {
            icon: 'mdi-text-box-multiple',
            title: 'Creatives',
            to: 'contentLibrary/creatives',
            hasClaim: claims.CL_CLM
          },
          {
            icon: 'mdi-chart-timeline-variant-shimmer',
            title: 'Data Feed',
            to: 'contentLibrary/dataFeed',
            hasClaim: claims.CL_DF
          }
        ]
      },
      {
        group: '',
        icon: 'mdi-cctv',
        title: 'Management',
        children: [
          {
            icon: 'mdi-text-box-check',
            title: 'Contract Activation',
            to: 'contractActivation',
            hasClaim: claims.MMS_TM
          },
          {
            icon: 'mdi-list-status',
            title: 'Spam Management',
            to: 'spamWordManagement',
            hasClaim: claims.MMS_TM
          },
          {
            icon: 'mdi-source-branch',
            title: 'Domain Summary',
            to: 'domainSummary',
            hasClaim: claims.MMS_TM
          },
          {
            icon: 'mdi-account-child',
            title: 'Manage Users',
            to: 'users',
            hasClaim: claims.AD_UV
          },
          {
            icon: 'mdi-shield-account',
            title: 'Admin Module',
            // to: 'admin',
            to: {
              name: 'NowwRedirect',
              params: { nowwRoute: 'admin_pass.aspx' }
            },
            hasClaim: claims.MMS_TM
          },
          {
            icon: 'mdi-tools',
            title: 'Campaign Admin',
            to: 'campaignAdmin',
            hasClaim: claims.MMS_TM
          },
          {
            icon: 'mdi-comment-quote',
            title: 'User Feedback Data',
            to: 'userFeedbackData',
            hasClaim: claims.MMS_TM
          },
          {
            icon: 'mdi-face-agent',
            title: 'CSS Admin Functions',
            to: {
              name: 'NowwRedirect',
              params: { nowwRoute: 'admin_css.aspx' }
            },
            hasClaim: claims.MMS_TEBT
          },
          {
            icon: 'mdi-file-edit',
            title: 'Address Change',
            to: 'admin/mailingAddress',
            hasClaim: claims.MMS_TM
          },
          {
            icon: 'mdi-web',
            title: 'Domain Omit Management',
            to: 'admin/domain',
            hasClaim: claims.MMS_TM
          },
          {
            icon: 'mdi-comment-quote',
            title: 'Email Address Management',
            to: 'admin/emailAddress',
            hasClaim: claims.MMS_TM
          },
          {
            icon: 'mdi mdi-responsive',
            title: 'Device Preview Options',
            to: 'admin/email-preview',
            hasClaim: claims.MMS_TM
          },
          {
            icon: 'mdi mdi-email-alert',
            title: 'Spam Hints Options',
            to: 'admin/spam-hints',
            hasClaim: claims.MMS_TM
          }
        ]
      }
    ]
  }),

  computed: {
    ...mapState(['barColor', 'drawer', 'displayDrawer']),
    ...mapGetters('auth', ['hasClaimKV']),
    drawerRender: {
      get () {
        return this.drawer
      },
      set (val) {
        this.setDrawer(val)
      }
    },
    computedItems () {
      return this.items.map(this.mapItem)
    },
    profile () {
      return {
        avatar: true,
        group: '',
        title: 'avatar',
        children: [
          {
            href: '',
            title: 'my-profile'
          },
          {
            to: '',
            title: 'edit-profile'
          },
          {
            to: '',
            title: 'settings'
          }
        ]
      }
    }
  },

  watch: {
    '$vuetify.breakpoint.smAndDown' (val) {
      this.$emit('update:expandOnHover', !val)
    }
  },

  mounted () {
    this.observer = new MutationObserver(mutations => {
      for (const m of mutations) {
        const newValue = m.target.getAttribute(m.attributeName)
        this.$nextTick(() => {
          this.onClassChange(newValue, m.oldValue)
        })
      }
    })

    this.observer.observe(document.getElementById('core-drawer'), {
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ['class']
    })
  },

  beforeDestroy () {
    this.observer.disconnect()
  },

  methods: {
    ...mapMutations(['setDrawer']),
    mapItem (item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title
      }
    },
    onClassChange (classAttrValue) {
      this.showDrawerImgIcon = classAttrValue
        .split(' ')
        .includes('v-navigation-drawer--mini-variant')
    },
    displayDrawerItem (item) {
      return !item.hasClaim || this.hasClaimKV(item.hasClaim)
    }
  }
}
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    &.v-navigation-drawer--mini-variant
      .v-list-item
        justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
