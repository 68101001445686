var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.displayDrawer
    ? _c(
        "v-navigation-drawer",
        _vm._b(
          {
            ref: "coreDrawer",
            staticStyle: { "z-index": "12" },
            attrs: {
              id: "core-drawer",
              app: "",
              dark:
                _vm.barColor !==
                "rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)",
              "expand-on-hover": _vm.expandOnHover,
              right: _vm.$vuetify.rtl,
              "mobile-breakpoint": "960",
              width: "260",
              color: "secondary"
            },
            model: {
              value: _vm.drawerRender,
              callback: function($$v) {
                _vm.drawerRender = $$v
              },
              expression: "drawerRender"
            }
          },
          "v-navigation-drawer",
          _vm.$attrs,
          false
        ),
        [
          _c(
            "router-link",
            { attrs: { to: { name: "CampaignDashboard" } } },
            [
              _c("v-img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showDrawerImgIcon,
                    expression: "!showDrawerImgIcon"
                  }
                ],
                staticClass: "ma-2",
                attrs: { src: require("@/assets/logo_white.png") }
              }),
              _c("v-img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showDrawerImgIcon,
                    expression: "showDrawerImgIcon"
                  }
                ],
                staticClass: "mx-2 my-7",
                attrs: { src: require("@/assets/logo_white_icon.png") }
              })
            ],
            1
          ),
          _c("v-divider", { staticClass: "my-2" }),
          _c(
            "v-list",
            { attrs: { expand: "", nav: "" } },
            [
              _c("div"),
              _vm._l(_vm.computedItems, function(item, i) {
                return [
                  item.divider
                    ? _c("v-divider", {
                        key: "divider-" + i,
                        staticClass: "my-2"
                      })
                    : item.children &&
                      _vm.displayDrawerItem(item) &&
                      item.children.some(function(x) {
                        return _vm.displayDrawerItem(x)
                      })
                    ? _c("base-item-group", {
                        key: "group-" + i,
                        attrs: { item: item }
                      })
                    : !item.children && _vm.displayDrawerItem(item)
                    ? _c("base-item", {
                        key: "item-" + i,
                        attrs: { item: item }
                      })
                    : _c("div", { key: "item-" + i, attrs: { hidden: "" } })
                ]
              }),
              _c("div")
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }